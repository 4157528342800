<template>
  <div>
    <general-table
      ref="userMessagesTable"
      api-url="admin/user_messages"
      add-type=""
      :view-content="true"
      :delete-content="false"
      :edit-content="false"
      viw-component="view-user-msg"
      type="page"
      :block-content="false"
      :columns="columns"
      :export-table="false"
      :search-box="false"
      :selectable="false"
    >

      <template #cell(message)="data">
        {{ cutMsg(data.item.message) }}
      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at }}
      </template>
    </general-table>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, min } from '@validations'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  components: { GeneralTable },
  data() {
    return {
      loader: false,
      required,
      min,
      columns: [
        { key: 'id' },
        { key: 'user_name', label: 'User Name' },
        { key: 'category_name_ar', label: 'Arabic Contact Type' },
        { key: 'category_name_en', label: 'English Contact Type' },
        { key: 'message', label: 'Message' },
        { key: 'created_at', label: 'Created At' },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const { formData, setFormData } = handleFormData()

    const form = ref({
      text: '',
      userId: '',
    })

    const errors = ref({})

    return {
      getValidationState,
      form,
      setFormData,
      formData,
      errors,
    }
  },
  methods: {
    timeFormat(date) {
      return moment(date).format('ddd DD MMM YYYY HH:mm:ss')
    },
    openModal({ id }) {
      this.form.userId = id
      this.$bvModal.show('supportMessage')
    },
    cutMsg(str) {
      if (str.length > 40) {
        return `${str.substring(0, 40)}...`
      }
      return str
    },
    closeModal() {
      this.form = {
        text: '',
        userId: '',
      }
      this.$bvModal.hide('supportMessage')
    },
    sendMessage() {
      this.setFormData(this.form)
      this.formData.append('_method', 'POST')
      axios.post(`ticket/${this.form.userId}/changeStatus`, this.formData).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.$refs.ticketsTable.getAllData()
          this.closeModal()
          this.$toasted.show('Message Sent Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
